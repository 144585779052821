// ReviewPrediction.js

import React from 'react';

class ReviewPrediction extends React.Component {
  render(){
    return (<p></p>);
    // return (<iframe src={perf }></iframe>);
  }
}

export default ReviewPrediction;
