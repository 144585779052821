import React from 'react';
import CoverImg from '../../assets/duolingo.jpeg';
import ss1 from '../../assets/ss1.png'
import ss2 from '../../assets/ss2.png'
import ss3 from '../../assets/ss3.png'
import ss4 from '../../assets/ss4.png'
import ss5 from '../../assets/ss5.png'

function DataInfra() {
  return (
    <div className="flex flex-col justify-between items-center min-h-screen bg-zinc-950 text-zinc-600 font-normal px-4 md:pt-8">
      <div className='max-w-md'>
        <div className=" mt-8 mb-2">
          <h1 className="text-4xl font-bold text-zinc-100">Fundamentally Valuing Duolingo</h1>
          <img src={CoverImg} className="mt-4" />
        </div>

        <div className="description flex justify-between items-center mb-6 text-zinc-500">
          <div className="author">
            <h2 className="text-sm">Spencer Mateega</h2>
          </div>
        
          <div className="date">
            <h2 className="text-sm">24 Nov 2021</h2>
          </div>
        </div>
        
        <div className="text-zinc-100 text-lg leading-7">
          <p className='italic mb-4'>
          This pitch was written during my first semester, as a freshman, at Wharton.
          </p>
        <p className='mb-4'>
          Duolingo (NASDAQ: DUOL) is an app-first language learning platform offering 40+ languages in a freemium and gamified go-to-market approach. DUOL monetizes its users through subscriptions (73% revenue), advertisements, in-app purchases, and English proficiency tests (“DET”). DUOL dominates the online language learning market, holding 67% market share with no other platform holding over 10%. The education app market faces an expected 27.5% CAGR from 2021 to 2026.
          </p>

          <p className='mb-4'>
            I write this post to propose a DCF-based, fundamental valuation of DUOL. 
          </p>

          <p className='mb-4'>
            Feel free to view the full model <u><a href="https://docs.google.com/spreadsheets/d/1VXJxmrDbbl2WaITJAoHaCApmqb0qLFNFr1jl-6V7QWY/edit?usp=sharing" target="_blank">here</a></u>.
          </p>

          <div class="zoom" className='mb-4'>
            <img src={ss1}/>
          </div>
          

          <p className='mb-4'>
            Modeling positive decreasing growth in Subscription, Advertising, and DET revenue segments yields a 23.4% CAGR from 2022 to 2030, conservatively less than the market CAGR of 27.5%. Subscription revenue, DUOL’s main cash generator, is driving this growth, making up nearly 90% of revenue by 2030 with a CAGR of 25.4%. Given the growth of Adverting and DET is relatively more uncertain, more conservative growth multiples were used.
          </p>

          <div class="zoom" className='mb-4'>
            <img src={ss2}/>
          </div>

          <p className='mb-4'>
            Steady EBIDTA margin expansion is assumed from a relative decrease in COGS, R&D, and SG&A spend as DUOL matures. It's hard to tell exactly how fast the startup will slow R&D, though a 2% YoY decrease seems reasonable given the currently large spend (41% of revenue).
          </p>

          <div class="zoom" className='mb-4'>
            <img src={ss3}/>
          </div>
          
          <p className='mb-4'>
            NWC metrics hold constant other than cash, which is projected to decrease back to 2021 levels. DUOL currently sits on a crazy amount of cash (>200% of sales) assumed to cover short-term revenue uncertainty. It seems reasonable to believe they will decrease their rainy-day funds to a normal level post 2022. 
          </p>

          <div class="zoom" className='mb-4'>
            <img src={ss4}/>
          </div>

          <p className='mb-4'>
            Cost of Equity is set to 10%, a typical opportunity cost of capital for most equities. Coupled with a Cost of Debt is set to 5% and a 0.987 equity-to-total capitalization ratio, we arrive at a WACC of 9.9%.
          </p>

          <p className='mb-4'>
            Given these assumptions and a 2.5% terminal growth rate, a $78.66 target share price is implied, a 14.7% upside from the current share price of $68.58 (at time of writing). 
          </p>
        </div>
      </div>

      <div className="flex justify-center mt-8 mb-6">
        <p className=" mx-2">[ <a className="hover:text-zinc-400" href="/blog">back</a> ]</p>
      </div>
    </div>
  );

}

export default DataInfra;
