import React from 'react';

function Blog() {
  return (
    <div className="flex flex-col justify-between items-center min-h-screen bg-zinc-950 text-zinc-600 text-md font-normal px-4">
      <div className="flex-grow flex flex-col justify-center max-w-xl px-4 py-8">
        <h2 className="text-xl font-bold mb-3">Blog ☁</h2>
        <ul>
          <li className="mb-3">
            <a className="hover:text-zinc-400 hover:cursor-help" href='/data-infra'>Data Infra: A 2-Minute Primer</a>
          </li>
          {/* <li className="mb-3">
            <a className="hover:text-zinc-400 hover:cursor-help" href='/review-prediction'>Predicting 5-Star Amazon Phone Reviews</a>
          </li> */}
          <li className="mb-3">
            <a className="hover:text-zinc-400 hover:cursor-help" href='/netflix'>Netflix, Inc. (NASDAQ: NFLX) Long Recommendation</a>
          </li>
          <li className="mb-3">
            <a className="hover:text-zinc-400 hover:cursor-help" href='/duolingo'>Fundamentally Valuing Duolingo</a>
          </li>
          <li className="mb-3">
            <a className="hover:text-zinc-400 hover:cursor-help" href='/facebook'>Summer 2023 Intern Project at Facebook</a>
          </li>
          {/* <li className="mb-3">
            <a className="hover:text-zinc-400 hover:cursor-help" href='/gamblers'>The Gambler's Ruin Problem</a>
          </li> */}
          <li className="mb-3">
            <a className="hover:text-zinc-400 hover:cursor-help" href='/byt'>[Repost] Wayzata Student Startup Supports Local Restaurants</a>
          </li>
        </ul>

        <div className="text-center mt-6">
          <p><span className="italic">"Reading furnishes the mind only with materials of knowledge; it is thinking that makes what we read ours."</span> — John Locke</p>
        </div>
      </div>

      <div className="flex justify-center mb-6">
        <p className=" mx-2">[ <a className="hover:text-zinc-400" href="/">home</a> ]</p>
      </div>
    </div>
  );
}

export default Blog;
