
function Home() {
  return (
    <div className="flex flex-col justify-between min-h-screen bg-zinc-950">
      <div className="flex-grow flex flex-col justify-center">
        <div className="text-zinc-700 max-w-72 leading-5 mx-auto animate-fadein">
          <p className="mb-3">i am <a className="text-zinc-200">SPENCER MATEEGA</a>.</p>
          {/* <p>i study finance and computer science at penn.</p> */}
          <p className="mb-3">i like to think about and build technology.</p>
          <p className="mb-3">i occasionally write <a className="italic hover:text-zinc-500" href="/blog">here</a> and <a className="italic hover:text-zinc-500" target="_blank" href="https://strategicrationale.substack.com/">here</a>.</p>
          <p className="mb-3">reach out at <a className=" hover:text-zinc-500" href="mailto:spencer@mateega.com">spencer@mateega.com</a>; i enjoy making new friends.</p>
        </div>
      </div>

      <div className="flex justify-center mb-6 animate-fadein">
        <p className="text-zinc-700 mx-2">[ <a className="hover:text-zinc-500" href="/about">about</a> ]</p>
        <p className="text-zinc-700 mx-2">[ <a className="hover:text-zinc-500" href="/blog">blog</a> ]</p>
        <p className="text-zinc-700 mx-2">[ <a className="hover:text-zinc-500" href="https://twitter.com/spencermateega" target="_blank">twitter</a> ]</p>
        <p className="text-zinc-700 mx-2">[ <a className="hover:text-zinc-500" href="https://www.linkedin.com/in/spencermateega/" target="_blank">linkedin</a> ]</p>
      </div>
    </div>
  );
}

export default Home;



