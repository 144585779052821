import React from 'react';
import CoverImg from '../../assets/appCover2.png';
import DemoVideo from '../../assets/social-investing-demo-no-audio.mp4';

function DataInfra() {
  return (
    <div className="flex flex-col justify-between items-center min-h-screen bg-zinc-950 text-zinc-600 font-normal px-4 md:pt-8">
      <div className='max-w-md'>
        <div className=" mt-8 mb-2">
          <h1 className="text-4xl font-bold text-zinc-100">Facebook Intern Project</h1>
          <img src={CoverImg} className="mt-4" />
        </div>

        <div className="description flex justify-between items-center mb-6 text-zinc-500">
          <div className="author">
            <h2 className="text-sm">Spencer Mateega</h2>
          </div>
        
          <div className="date">
            <h2 className="text-sm">Summer 2022</h2>
          </div>
        </div>
        
        <div className="text-zinc-100 text-lg leading-7">
          <p className='mb-4'>
            During my internship at Facebook, I full stack developed a social investing app. Although I never pushed my app to the Play Store, I learned the ins and outs of basic Android development and had the chance to learn more about the Oculus Mobile team (shout out Keion :)). 
          </p>

          <p className='mb-4'>
            Learn more about my Android app below:
          </p>

          <h3 className='text-2xl font-bold mb-2'>
            Problem
          </h3>

          <p className='mb-4'> 
            Despite a relatively massive shift towards collaborative and social trading, there is no easy way for retail investors to actually trade together. Further, investing in cryptocurrencies is relatively difficult, with many investors wanting exposure to altcoins but not knowing enough about the crypto market to actually make trades themself.
          </p>

          <h3 className='text-2xl font-bold mb-2'>
            Solution
          </h3>

          <p className='mb-4'> 
            This app aims to make cryptocurrency investing easier by allowing investors to trade together. Within the app, users can join groups and deposit paper money into the group’s pool of capital. Within each group, there are designated “investors” and “members.” Investors can trade the group’s pool of capital on behalf of the members. Each group is also equipped with a chat to encourage collaboration and communication before and after trades are executed. Essentially, this app enables anyone to create a digital crypto hedge fund. 
          </p>

          <h3 className='text-2xl font-bold mb-2'>
            Demo
          </h3>

          <video width="100%" controls className='mb-4'>
            <source src={DemoVideo} type="video/mp4"/>
            Your browser does not support the video tag.
          </video>

          <h3 className='text-2xl font-bold mb-2'>
            Highlights
          </h3>

          <ul className='list-disc mb-4 pl-4'>
            <li>
              I built everything myself, from the UI to the database schema and implementation.
            </li>
            <li>
              Push notifications, with deep linking, were implemented through a Firebase Cloud Function.
            </li>
            <li>
              To increase performance, I decreased database and API calls by 69.51% (mainly through memory caching).
            </li>
            <li>
              8 app pages were implemented.
            </li>
          </ul>

          <h3 className='text-2xl font-bold mb-2'>
            Intended Next Features
          </h3>

          <p className='mb-4'>
            Given I only had a finite amount of time to work on this project (the duration of the internship), there naturally were some features I was still working on but had not completed. Some of the next features I was planning to implement were:
          </p>

          <ul className='list-disc mb-4 pl-4'>
            <li>
              Polls to allow non-investors to pitch investment ideas.
            </li>
            <li>
              A graph on the trading details page (where there are currently “sell” and “buy” buttons) to visualize past trading data.
            </li>
            <li>
              Chat replies and reactions to facilitate more involved conversations.      
            </li>
          </ul>

          <h3 className='text-2xl font-bold mb-2'>
            Code
          </h3>

          <div class="color-highlight">
            <p className='mb-4'>
              The code for this project can be found <u><a className='hover:text-zinc-500' href="https://github.com/mateega/social-investing-public" target="_blank">here</a></u>.
            </p>
          </div>
        </div>
      </div>

      <div className="flex justify-center mt-8 mb-6">
        <p className=" mx-2">[ <a className="hover:text-zinc-400" href="/blog">back</a> ]</p>
      </div>
    </div>
  );

}

export default DataInfra;
