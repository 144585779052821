import React from 'react';
import fig1 from '../../assets/fig1.png'
import fig2 from '../../assets/fig2.png'
import fig3 from '../../assets/fig3.png'
import fig4 from '../../assets/fig4.png'
import fig5 from '../../assets/fig5.png'
import fig6 from '../../assets/fig6.png'
import fig7 from '../../assets/fig7.png'

function DataInfra() {
  return (
    <div className="flex flex-col justify-between items-center min-h-screen bg-zinc-950 text-zinc-600 font-normal px-4 md:pt-8">
      <div className='max-w-md'>
        <div className=" mt-8 mb-2">
          <h1 className="text-4xl font-bold text-zinc-100">Netflix, Inc. (NASDAQ: NFLX) Long Recommendation</h1>
          <img src={'https://partnerhelp.netflixstudios.com/hc/article_attachments/4404389437843'} className="mt-4" />
        </div>

        <div className="description flex justify-between items-center mb-6 text-zinc-500">
          <div className="author">
            <h2 className="text-sm">Spencer Mateega</h2>
          </div>
        
          <div className="date">
            <h2 className="text-sm">14 Jan 2023</h2>
          </div>
        </div>
        
        <div className="text-zinc-100 text-lg leading-7">
          <p className='mb-4'><i>
            Netflix is expected to report 4Q2022 earnings on January 19th, 2023 and 1Q2023 earnings in early April.
          </i></p>

          <h3 className='text-2xl font-bold mb-2'>
            Thesis Statement
          </h3>

          <p className='mb-4'>
            I recommend a long position in NFLX with a 3-month investment horizon as 1) Street is understating the TAM increase and overstating profit margin reduction from the rollout of NFLX’s new Basic with Ads tier, and 2) crackdown on password sharing will drive long-term revenue growth, with paid sharing kicked off in early 2023. Further, a market shift from Linear TV to streaming tailwinds 2023. FX will substantially headwind revenue.
          </p>

          <h3 className='text-2xl font-bold mb-2'>
            Business Overview 
          </h3>

          <p className='mb-4'>
            Netflix, Inc. (NFLX) is one of the world’s leading providers of on-demand internet streaming media. NFLX operates across four key regions: UCAN (United States and Canada), EMEA (Europe, Middle East, and Africa), LATAM (Latin America), and APAC (Asia Pacific). The vast majority (99.6%) of NFLX’s revenue is derived from monthly membership fees for services related to streaming content. The company also offers its legacy DVD-by-mail service in the US. As of 3Q22, the company has approximately 223m paid subscribers at a monthly ARPU of $11.85. 
          </p>
          <p className='mb-4'>
            In the LTM, NFLX has done $31.47B in revenue, $6.02B in EBITDA, and $5.04B in net income. As of January 9, 2023, NFLX trades at $316.83 per share at a 26.97 P/E multiple (Appendix Fig. 1). 
          </p>

          <h3 className='text-2xl font-bold mb-2'>
            Industry Overview 
          </h3>

          <p className='mb-4'>
            NFLX operates in the increasingly competitive streaming industry. As of November 2022, streaming represents 38.2% of overall US TV viewership (Appendix Fig. 2), up from 28.5% in November 2021 (34% CAGR). Streaming is now the largest TV viewership segment (Appendix Fig. 3).
          </p>
          <p className='mb-4'>
            NFLX primarily competes against Disney+ and HBO (Appendix Fig. 4).  Competitors have recently taken serious market share from NFLX (NFLX’s share is down from 27% in 3Q2021 to 21% in 3Q2022, according to JustWatch.com).
          </p>
          <p className='mb-4'>
            Compared to Disney+’s $7.99/month and HBO Max’s $9.99/month ad-supported tiers, NFLX offers a cheaper ad tier (“Basic with Ads”) at $6.99/month. NFLX also offers ad-free plans at $9.99/month (“Basic”), $15.49/month (“Standard”), and $19.99/month (“Premium”), compared to Disney+’s $10.99/month and HBO Max’s $14.99/month ad-free tiers.
          </p>

          <h3 className='text-2xl font-bold mb-2'>
            Supporting Thesis Points
          </h3>
          
          <h5 className='text-xl font-bold mb-2'>
            1. Understated TAM Increase and Overstated Profit Margin Reduction from Basic with Ads Tier
          </h5>

          <p className='mb-4'>
            HBO Max launched its ad-supported tier in 2Q2021. Within a year (2Q2022), 12% of HBO Max’s subscribers were on the ad-tier and the ad-tier was driving 31% of new gross additions. Out of the ad-supported new additions, 24% were downgrades from the ad-free tier, 40% were new subscribers, and 36% were re-subscribers (users who had previously subscribed and since canceled their subscription).
          </p>

          <p className='mb-4'>
            From HBO Max’s ad-tier launch, we don’t expect NFLX’s ad-tier’s adoption to explode overnight, but rather grow at a conservative pace. Overall churn is expected to decrease, from users who are on the fence about their NFLX subscription (downgrades, re-subscribers) who get converted to the ad-tier. 
          </p>

          <p className='mb-4'>
            With negligible switching costs between platforms and little technological differentiation (i.e., recommendation algorithms), customer loyalty is low in the streaming industry. Customers primarily their provider based on 1) subscription cost and 2) quality of content.  As NFLX’s ad-tier undercuts Disney+’s and HBO Max’s ad-tiers on price, NFLX will be able to take market share back through customers who have reduced discretionary income. All three company’s ad-tiers offer a similar experience (no downloads, 4-5 minutes of ads/hour). Investors have overlooked NFLX’s opportunity to take back market share from competitors. Further, NFLX can pick up users who had previously been reluctant to purchase Netflix Basic at $9.99/month but can afford a $6.99/month subscription. 
          </p>

          <p className='mb-4'>
            Investors are overestimating the number of users that will convert from an ad-free plan to the ad-supported plan. The ad-supported tier should not affect the Standard and Premium tiers (otherwise these users would have switched to the Basic tier in the past). A 2018 estimate,  puts Basic users at 27% of total users, Standard at 43% and Premium at 30%. Hence, we only need to worry about at most 27% of users downgrading. This is significantly less than consensus estimates, which frequent cite a survey that suggests 46% of users consider downgrading.  
          </p>

          <p className='mb-4'>
            Assuming 27% of total users are currently on the Basic plan and 50% of Basic users downgrade, NFLX can expect an 4% decrease in revenue with Basic with Ads being 30% cheaper than Basic without Ads (27%*50%*30%=4%). This is significantly less than revenue decreases projected by bears and doesn’t factor in the upside of the new users NFLX will attract. 
          </p>

          <p className='mb-4'>
            An advertising ARPU estimate can be calculated using some back-of-the-envelope math. 
          </p>

          <p className='mb-4'>
            Assumptions: NFLX is pricing CPMs (the cost of reaching 1,000 people) at $60-$65 (or $0.06-$0.065 per impression), ads air 4-5 minutes per hour, ad lengths average 20-30 seconds (0.33-0.5 minutes), and users watch 1 hour of content per day (estimate). This gives us: (ad minutes per hour)/(minutes per ad)*(hours watched per day)*(days per month)*(revenue per impression) = (4-5)/(0.33-0.5)*(1)*(30)*($0.06-$0.065) = $14.4-$29.25 monthly ARPU from advertising revenue, or total monthly ARPU of $21.39-$36.24 for ad-tier users, once we add in the $6.99 subscription fee.
          </p>

          <p className='mb-4'>
            With NFLX’s current monthly ARPU of $11.85, full rollout of the ad-tier will be highly accretive. 
          </p>

          <p className='mb-4'>
            In 4Q2022, I expect 1% of NFLX subscribers to be on the Basic with Ads plan, increasing to 12% by 2023, and 17% by 2027. At a $14.40 monthly ARPU, Basic with Ads will add $98m of revenue in 4Q2022, $3.7B in 2023, and $8.4B in 2027.  
          </p>

          <h5 className='text-xl font-bold mb-2'>
            2. Crack Down on Password Sharing Drives Long-Term Revenue
          </h5>

          <p className='mb-4'>
            To crack down on password sharing, NFLX is anticipated to launch a paid sharing feature in early 2023. Users will have the ability to “add an extra member” or “add a home” to their NFLX accounts for an extra fee. NFLX has tested this feature at a fee of 23%-29% of the Standard plan, suggesting that it could cost $3.50-$4.50 to add an extra member or home in the US.
          </p>

          <p className='mb-4'>
            NFLX estimates there are more than 100m non-paying households worldwide, including over 30m in UCAN, violating its password sharing rules. This is a shockingly high number against the company’s 223m total paid subscribers. If NFLX can capture any meaningful percentage of this 100m, it has a large revenue growth opportunity. 
          </p>

          <p className='mb-4'>
            In a consumer survey from Cowen asking password sharers if they would pay an extra $3/month, 50% of respondents said either they or the primary account holder would do so. However, I think users are less likely to actually switch compared to what they say. At 33% switching, NFLX has the opportunity to add 10m paid sharers, a $420-$540m (30m*33%*$3.50-$4.50*12 months) increase to annual revenue in the UCAN alone. 
          </p>

          <p className='mb-4'>
            However, NFLX won’t immediately recognize this revenue opportunity as its users most likely will not face repercussions for not paying the extra fee. The company’s help page on password sharing currently says, “Netflix will not automatically charge you if you share your account with someone who doesn’t live with you.” Hence, I see paid sharing as a revenue opportunity, but in the long term. 
          </p>

          <h3 className='text-2xl font-bold mb-2'>
            Valuation
          </h3>

          <p className='mb-4'>
            Performing a DCF valuation implied an EV of $183B and a share price of $408.92. 
          </p>

          <h3 className='text-2xl font-bold mb-2'>
            Risks and Mitigants
          </h3>

          <h5 className='text-xl font-bold mb-2'>
            1. NFLX is charging way too much for ad space and won’t be able to achieve a $60-$65 CPM
          </h5>

          <p className='mb-4'>
            Yes, NFLX is charging more than its competitors on a CPM basis. This charge can be justified through a large relative subscriber base (223m) compared to competitors (AMZN Prime Video: 200m, Disney+: 164m, HBO Max: 95m). Even if NFLX charges a $50 CPM, which is in line with HBO Max and Disney+ (Appendix Fig. 6), the Basic with Ad tier would still be accretive to ARPU ($18.99-$29.49 monthly ARPU using the previously stated calculation).
          </p>

          <h5 className='text-xl font-bold mb-2'>
            2. NFLX’s valuation was built around the premise of rapid growth – this is no longer the reality
          </h5>

          <p className='mb-4'>
            At this point, I have yet to address NFLX’s fundamental revenue problem. Yes, NFLX is no longer significantly adding subscribers. NFLX has shifted attention from subscriber growth to revenue growth. This is natural for a maturing subscription company. The subscription business is focused on two metrics: 1) number of subscribers and 2) ARPU. NFLX is just now focusing on 2. Further, NFLX still has room to grow, with only penetrating 5% of its 800-900B TAM. 
          </p>

          <h5 className='text-xl font-bold mb-2'>
            3. NFLX is rapidly losing market share in an increasingly competitive market
          </h5>

          <p className='mb-4'>
            Video steaming has undoubtedly become a tough industry structure for all players. The reality is there are >6 major players, barriers to entry are low, and there is low differentiation across platforms. However, I believe that NFLX is still a share gainer. With Linear TV still representing over 60% of viewership (Appendix Fig. 2), the streaming industry has opportunities to continue to grow. This presents NFLX with room to grow. Further, many competitors are still split between splitting their resources between linear cash cow businesses and expanding DTC outlets, while facing pressure from questioned sub-scale DTC platform profitability. 
          </p>

          <h3 className='text-2xl font-bold mb-2'>
            Catalysts and Event Path
          </h3>

          <p className='mb-4'>
            I expect my theses to be realized after the mass rollout of Basic with Ads and paid sharing (expected to be heavily covered at 1Q2023 earnings). At the coming 4Q2022 earnings, I would like to hear the rollout of NFLX’s ad-tier has been going smoothly and that paid sharing will be launched in 1Q2023. At 4Q2022, I have no revenue expectation from advertising (~$98m). At 1Q2023 earnings, I would like to see strong numbers behind the Basic with Ads launch (~$497m in advertising revenue) and a smooth initial rollout of paid sharing. My theses align with a net adds roughly in line with 4Q2022 consensus (+4.5m) and a 1Q2023 consensus (+2.5m) beat. By 2023, I would expect $3.7B in revenue from advertising and a large number of users already using paid sharing (~5m) to have my theses fully realized.
          </p>

          <h5 className='text-xl font-bold mb-2'>
            A note on gaming
          </h5>

          <p className='mb-4'>
            I also investigated the opportunity presented by NFLX entering the gaming market. My main conclusion was that gaming will not alone drive much revenue, though it can act as a brand booster:
          </p>

          <p className='mb-4'>
            One of the two fronts NFLX is battling its competitors is quality/desire of content. Entering gaming is a differentiated approach and will be a new way to keep subscribers hooked on NFLX.
          </p>

          <p className='mb-4'>
            Of 12 brokers that actively cover NFLX, only two have seriously priced gaming into their NFLX price target (Appendix Fig. 5).
          </p>

          <p className='mb-4'>
            NFLX will be entering gaming through mobile and cloud gaming (TV and PC). At the moment, NFLX’s gaming business remains very small in comparison with video content spend, and the company is rapidly expanding the business through acquisitions (NFLX acquired 3 mobile game studios in 2022). 
          </p>

          <p className='mb-4'>
            In 2021, the global video game market size was valued at $195.65B and is expected to expand at a CAGR of 12.9% from 2022 to 2030. Entering gaming, significantly expands NFLX’s TAM (the gaming industry generated $192.7B in revenue in 2021 vs $99.7B from video entertainment). 
          </p>

          <p className='mb-4'>
            NFLX has a distinct advantage in an industry where few gamers have loyalties to a given game developer, rather they care about the actual game (i.e., GTA or Mario). This positions NFLX nicely to take advantage of its existing IP, for gamers to interact with the worlds, stories, and characters in NFLX’s shows and films. 
          </p>

          <p className='mb-4'>
            To quantify NFLX’s gaming business, we take the company’s internal game studio in Helsinki, Finland as an example. The studio is led by Marko Lastikka, who has been responsible for leading FarmVille 3 (Zynga) and SimCity BuildIt (EA).  Both drew in $2m in revenue per month.  If NFLX reaches 10 studios by end of 2023 (doubling 2022 M&A pace) and each studio develops a successful mobile game (both unlikely assumptions), NFLX can expect to increase annual revenue by $240m. Given NFLX does over $30B in revenue, the mobile business won’t be a direct driver of revenue, at least in the short term.
          </p>

          <h5 className='text-xl font-bold mb-2'>
            Appendix
          </h5>

          <img src={fig1} className='mt-4 max-h-60'/>
          <p className='mb-4'>Figure 1</p>

          <img src={fig2} className='mt-4 max-h-60'/>
          <p className='mb-4'>Figure 2</p>

          <img src={fig3} className='mt-4 max-h-60'/>
          <p className='mb-4'>Figure 3</p>

          <img src={fig4} className='mt-4 max-h-60'/>
          <p className='mb-4'>Figure 4</p>

          <img src={fig5} className='mt-4 max-h-60'/>
          <p className='mb-4'>Figure 5</p>

          <img src={fig6} className='mt-4 max-h-60'/>
          <p className='mb-4'>Figure 6</p>

          <img src={fig7} className='mt-4 max-h-60'/>
          <p className='mb-4'>Figure 7</p>

        </div>
      </div>

      <div className="flex justify-center mt-8 mb-6">
        <p className=" mx-2">[ <a className="hover:text-zinc-400" href="/blog">back</a> ]</p>
      </div>
    </div>
  );

}

export default DataInfra;
