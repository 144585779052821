import React from 'react';
import CoverImg from '../../assets/team.jpeg';
import BytScreenshot from '../../assets/byt_screenshot.jpeg'

function DataInfra() {
  return (
    <div className="flex flex-col justify-between items-center min-h-screen bg-zinc-950 text-zinc-600 font-normal px-4 md:pt-8">
      <div className='max-w-md'>
        <div className=" mt-8 mb-2">
          <h1 className="text-4xl font-bold text-zinc-100">[Repost] Wayzata Student Startup Supports Local Restaurants</h1>
          <img src={CoverImg} className="mt-4" />
        </div>

        <div className="description flex justify-between items-center mb-6 text-zinc-500">
          <div className="author">
            <h2 className="text-sm">Jenna Grundtner</h2>
          </div>
        
          <div className="date">
            <h2 className="text-sm">2 Apr 2021</h2>
          </div>
        </div>
        
        <div className="text-zinc-100 text-lg leading-7">
        <p className='mb-4'><em>
          Article <u><a href="https://tcbmag.com/wayzata-student-startup-supports-local-restaurants/" target="_blank">reposted</a></u> from Twin Cities Business
        </em></p>
        <p className='mb-4'>
          A new local startup called Byt, launched on March 5 by Wayzata High School seniors 
          Spencer Mateega and Carson Bring, aims to help Minneapolis restaurants gain more 
          business by providing customers with coupons.
        </p>
        <p className='mb-4'>
          The idea is simple. Users register online and then text what type of food they want 
          to Byt’s phone number (provided after registration). Byt’s algorithm then responds 
          with a coupon to a local restaurant that matches the ask. For example, a user may 
          text “pizza” and receive a coupon to a local pizza restaurant. The user then shows 
          their coupon to the cashier or waiter to redeem their discount.
        </p>
        <p className='mb-4'>
          If a user requests a food that isn’t offered by one of the participating restaurants, 
          the algorithm will respond with a message saying it doesn’t have a matching promotion 
          currently available. Users can also text the word “promos” to see all current coupon 
          offerings. Occasionally, the algorithm will send push notifications on a restaurant’s 
          behalf, such as a reminder about a deal expiring soon.
        </p>
        <img id='byt-img' src={BytScreenshot} className='mb-4 max-w-64 mx-auto'/>
        <p className='mb-4'>
          Because Byt doesn’t require an app download for users or system integration for 
          restaurants, Mateega said Byt is seamless and simple for all parties involved.
        </p>
        <p className='mb-4'>
          The startup’s impact is twofold: Local restaurants gain customers and increased 
          visibility, and users save money while supporting local businesses.
        </p>
        <p className='mb-4'>
          “People are able to save a little money on food, while supporting the Minnesota 
          economy and small business owners in the process,” Mateega said.
        </p>
        <p className='mb-4'>
          Inspiration for the startup came from a shared passion for technology, business, 
          and entrepreneurship, Mateega said, as well as a desire to help the community get 
          through Covid-19.
        </p>
        <p className='mb-4'>
          “We wanted to make something meaningful,” Mateega said. “It really started with 
          the restaurants and how hard they’ve been hit, and then we brainstormed further 
          into helping college kids save money during the pandemic, too.”
        </p>
        <p className='mb-4'>
          Mateega tackles the business side of the startup, while co-founder Carson Bring 
          handles the tech and coding. Wayzata High School junior Nina Berglund helps the 
          duo to take charge of digital marketing and communications.
        </p>
        <p className='mb-4'>
          So far, Byt is only in Dinkytown, partnering with Nutrition U, Avocadish, Banh 
          Appetit, Mi Tea, and Korean Bowl. Mateega said the company plans to expand further 
          into the Twin Cities and Minnesota and to launch in other college towns as well.
        </p>
        <p className='mb-4'>
          “I was blown away by Spencer and Carson’s energy, passion, and confidence, and I 
          wanted to support that,”said Alex Varouhas, Avocadish founder. “We just recently 
          launched with them and have had some success already; it’s been worthwhile for us.”
        </p>
        <p className='mb-4'>
          Despite both co-founders moving out of state for college this fall, the team is 
          confident that Byt will continue to grow. Berglund is already working on marketing 
          strategy with two students at the University of Minnesota, and Mateega and Bring 
          plan to establish Byt on their university campuses—University of Pennsylvania and 
          North Dakota State University.
        </p>
        <p className='mb-4'>
          “This summer, we’re diving into Minneapolis and Minnesota to prove there is demand 
          for this idea,” Mateega said. “From there, we’ll expand it next year to our 
          colleges as the Minneapolis base continues to grow.”
        </p>
        </div>
      </div>

      <div className="flex justify-center mt-8 mb-6">
        <p className=" mx-2">[ <a className="hover:text-zinc-400" href="/blog">back</a> ]</p>
      </div>
    </div>
  );

}

export default DataInfra;
